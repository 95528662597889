import { FunctionComponent, 
         useCallback, 
         useContext, 
         useEffect, 
         useState } from "react";
import { Consumption } from "./Consumption";
import * as consumptionIds from "../../__shared__/luminateIds/consumptionIds.json";
import axios from "../../__shared__/axios"; 
import { ClientContext } from "../../../contexts/ClientContext";
import {AllClients} from "../AllClients/AllClients";
import { TopSongs } from "../TopSongs/TopSongs";
import PhotoHolder from "../AllClients/Photo/PhotoHolder";
import GlobalStreamingChart from "./GlobalStreamingChart/GlobalStreamingChart";
import RocnationImage from '../../../assets/rn_logo_black.png'; 

type ContainerProps = {
    weekId: string; 
}

export const Container: FunctionComponent<ContainerProps> = (props) => { 

    const {clientOptions,setClientOptions}  = useContext(ClientContext);
    const { client, setClient } = useContext(ClientContext);
    const { isRefreshed, setIsRefreshed } = useContext(ClientContext);
    const { clientDiscography, setClientDiscography } = useContext(ClientContext);
    const { clientItem, setClientItem } = useContext(ClientContext);
    const [photoUrl, setPhotoUrl] = useState<string | null>(null);
    const [photoName, setPhotoName] = useState<string | null>(null);

    const { isAlbumOrTrack, setAlbumOrTrack } = useContext(ClientContext);
   // const [_clientItem, _setClientItem] = useState<string>("");  
    const [_clientData, _setClientData] = useState<any>(null); 

    const [_globalStreams, _setGlobalStreams] = useState<any>({tp: 0, lp: 0, ytd: 0, atd: 0})
    const [_globalAudioStreams, _setGlobalAudioStreams] = useState<any>({tp: 0, lp: 0, ytd: 0, atd: 0})
    const [_globalVideoStreams, _setGlobalVideoStreams] = useState<any>({tp: 0, lp: 0, ytd: 0, atd: 0})

    const [_globalTrackDownloads, _setGlobalTrackDownloads] = useState<any>({tp: 0, lp: 0, ytd: 0, atd: 0})
    const [_usPhysicalAlbums, _setUSPhysicalAlbums] = useState<any>({tp: 0, lp: 0, ytd: 0, atd: 0})
    const [_totalConsumption, _setTotalConsumption] = useState<any>({tp: 0, lp: 0, ytd: 0, atd: 0})

    const [_trackGainers, _setTrackGainers] = useState<any[]>([{Title: '', ['Last Period']: 0, ['This Period']: 0, YTD: 0, ['% Chg']: 0}])
    const [_weeklyConsumption, _setWeeklyConsumption] = useState<any>({})

    

    const handleRefreshClient = () => { 
        if (!!client?.MusicConnectArtistID) { 
            _setClientData((data: any) => { 
                const dataCopy = {...data}; 
                dataCopy[client] = {}; 
                return dataCopy; 
            }) 
        }
        setIsRefreshed(true); 

    }

    const handlePhotoUrl = (url:string) => {
        setPhotoUrl(url);
    }

    const handleSelectClient = (name: string) => { 
        if (_globalStreams["tp"] > 0) { 
            _setGlobalStreams({tp: 0, lp: 0, ytd: 0, atd: 0}); 

        }
        if (_globalAudioStreams["tp"] > 0) { 
            _setGlobalAudioStreams({tp: 0, lp: 0, ytd: 0, atd: 0});
        }        
        if (_globalVideoStreams["tp"] > 0) { 
            _setGlobalVideoStreams({tp: 0, lp: 0, ytd: 0, atd: 0});

        }
        if (_globalTrackDownloads["tp"] > 0) { 
            _setGlobalTrackDownloads({tp: 0, lp: 0, ytd: 0, atd: 0});

        }
        if (_usPhysicalAlbums["tp"] > 0) { 
            _setUSPhysicalAlbums({tp: 0, lp: 0, ytd: 0, atd: 0});

        }
        if (_totalConsumption["tp"] > 0) { 
            _setTotalConsumption({tp: 0, lp: 0, ytd: 0, atd: 0}); 
        }
    }

    const handleRefresh = () => { 
        if (_globalStreams["tp"] > 0) { 
            _setGlobalStreams({tp: 0, lp: 0, ytd: 0, atd: 0}); 

        }
        if (_globalAudioStreams["tp"] > 0) { 
            _setGlobalAudioStreams({tp: 0, lp: 0, ytd: 0, atd: 0});
        }        
        if (_globalVideoStreams["tp"] > 0) { 
            _setGlobalVideoStreams({tp: 0, lp: 0, ytd: 0, atd: 0});

        }
        if (_globalTrackDownloads["tp"] > 0) { 
            _setGlobalTrackDownloads({tp: 0, lp: 0, ytd: 0, atd: 0});

        }
        if (_usPhysicalAlbums["tp"] > 0) { 
            _setUSPhysicalAlbums({tp: 0, lp: 0, ytd: 0, atd: 0});

        }
        if (_totalConsumption["tp"] > 0) { 
            _setTotalConsumption({tp: 0, lp: 0, ytd: 0, atd: 0}); 
        }
        // setIsRefreshed(true); 
    }


    const getGlobalStreams = useCallback(async () => { 
        try { 

            const body = {
                weekId: props.weekId,
                artistId: clientOptions.find(x => x.Artist == client.Artist)["MusicConnectArtistID"]
            }

            const res = await axios.post(`/artist/g1/streaming`, body)
            if (res instanceof Error) 
                throw (res); 
            
            if (res["status"] === 200 && res["data"] !== null) { 
                if (res["data"]["error"])
                     throw (res["data"]["error"]); 

                const total = res["data"]["total"]; 
                const audio = res["data"]["audio"]; 
                const video = res["data"]["video"]; 
                _setGlobalStreams(total); 
                _setGlobalAudioStreams(audio); 
                _setGlobalVideoStreams(video); 
            }
            
        } catch (err) { 

            console.log("GET GLOBAL STREAMS err ", err); 
        }
    }, [client]); 

    const getGlobalTrackDownloadsArtist = useCallback(async () => { 
        try { 
            const body = {
                weekId: props.weekId,
                artistId: clientOptions.find(x => x.Artist == client.Artist)["MusicConnectArtistID"]
            }

            const res = await axios.post(`/artist/g1/track-downloads`, body)
            if (res instanceof Error) throw (res); 
            
      
            if (res["status"] === 200 && res["data"] !== null) { 
                if (res["data"]["error"]) throw (res["data"]["error"]); 

                const total = res["data"]["total"]; 
                _setGlobalTrackDownloads(total); 
            }
        } catch (err) { 
            console.log("GET GLOBAL TRACK DOWNLOADS err ", err); 

        }
    }, [client]); 

    const getUSPhysicalAlbumsArtist = useCallback(async () => { 
        try { 
            const body = {
                weekId: props.weekId,
                artistId: clientOptions.find(x => x.Artist == client.Artist)["MusicConnectArtistID"]
            }

            const res = await axios.post(`/artist/us/physical-albums`, body)
            if (res instanceof Error) throw (res); 
            
      
            if (res["status"] === 200 && res["data"] !== null) { 
                if (res["data"]["error"]) throw (res["data"]["error"]); 
                const total = res["data"]["total"]; 
                _setUSPhysicalAlbums(total); 
            }
        } catch (err) { 
            console.log("GET US PHYSICAL ALBUMS err ", err); 

        }
    }, [client]);

    const getTrackGainers = useCallback(async () => { 
        try { 
            if(!client?.MusicConnectArtistID){
            const res = await axios.get(`/track/top-gainers`)
            if (res instanceof Error) throw (res); 
            if (res["status"] === 200 && res["data"] !== null) { 
                if (res["data"]["error"]) throw (res["data"]["error"]); 
                const tracks = res["data"]; 
                
                _setTrackGainers((data: any) => { 
                    return tracks;      
                })
            }
        }
        } catch (err) { 
            console.log("GET TRACKS GAINERS err ", err); 
        }
    }, [client])

    const getTotalConsumption = useCallback(async () => { 
        try { 
            const body = {
                weekId: props.weekId,
                artistId: clientOptions.find(x => x.Artist == client.Artist)["MusicConnectArtistID"]
            }
            const res = await axios.post(`/artist/us/total-consumption`, body)
            if (res instanceof Error) throw (res); 
            if (res["status"] === 200 && res["data"] !== null) { 
                if (res["data"]["error"]) throw (res["data"]["error"]); 
                const total = res["data"]["total"]; 
                _setTotalConsumption(total); 
            }
        } catch (err) { 
            console.log("GET TOTAL CONSUMPTION err ", err); 

        }
    }, [client]); 

    const getWeeklyMusicConsumption = useCallback(async () => { 
        const res = await axios.post('/artist/music-consumption', {
            weekId: props.weekId,
            artist: client.Artist,
          });
        
        if (res instanceof Error) throw (res); 
        if (res["status"] === 200 && res["data"] !== null) { 
            if (res["data"]["error"]) throw (res["data"]["error"]); 
            const weeklyConsumption = res["data"]; 
            _setWeeklyConsumption(weeklyConsumption); 
        }

    }, [client]);
    
    useEffect(() => {
            if (!!client?.MusicConnectArtistID) { 
                        getTotalConsumption();
                        getWeeklyMusicConsumption();

                        getGlobalTrackDownloadsArtist()
                        getGlobalStreams();
                        getUSPhysicalAlbumsArtist();
                        var imageName= client.Artist.replace(/ /g, "_");
                        setPhotoName(imageName);
                        console.log('Loading data for client:', client);
                    }
            else {
                getTrackGainers();
            }
          }, [client, isRefreshed]);


    const getAlbumUSStreaming = useCallback(async (albumId: number, name: string) => { 
        try { 
            const body = { 
                albumId,
                weekId: props.weekId
            }

            const res = await axios.post(`/album/us/streaming`, body)
            if (res instanceof Error) throw (res); 
            
      
            if (res["status"] === 200 && res["data"] !== null) { 
                if (res["data"]["error"]) throw (res["data"]["error"]); 

                const total = res["data"]["total"]; 
                    
                _setClientData((data: any) => { 
                         
                    if (data === null || !(clientItem in data)) { 

                        data = {
                            [clientItem]: { 
                                "U.S. Total Streaming": {
                                    tp: total["tp"],
                                    lp: total["lp"],
                                    ytd: total["ytd"],
                                    atd: total["atd"]
                                }
                            }
                        }

                        console.log("hi man")

                    } else { 
                            
                        data[clientItem]["U.S. Total Streaming"] = {tp: total["tp"],
                                                                     lp: total["lp"],
                                                                     ytd: total["ytd"],
                                                                     atd: total["atd"]}
                        
                        console.log("data again ", data); 
                    } 

                    return {...data}; 
                
                }) 
            }
        } catch (err) { 
            console.log("GET ALBUM US STREAMING err ", err); 
        }
    }, [clientItem])

    const getAlbumUSTotalConsumption = useCallback(async (albumId: number, name: string) => { 
        try { 
            const body = { 
                albumId,
                weekId: props.weekId
            }

            const res = await axios.post(`/album/us/total-consumption`, body)
            if (res instanceof Error) throw (res); 
              
            if (res["status"] === 200 && res["data"] !== null) { 
                if (res["data"]["error"]) throw (res["data"]["error"]); 

                const total = res["data"]["total"]; 
                _setClientData((data: any) => { 
     
                    if (data === null || !(clientItem in data)) { 
                        data = {
                            [clientItem]: { 
                                "Total Consumption": {
                                    tp: total["tp"],
                                    lp: total["lp"],
                                    ytd: total["ytd"],
                                    atd: total["atd"]
                                }
                            }
                        }
                        console.log('hi man 2 ')
                    } else { 
                        data[clientItem]["Total Consumption"] = {tp: total["tp"],
                                                                  lp: total["lp"],
                                                                  ytd: total["ytd"],
                                                                  atd: total["atd"]}

                        console.log("data dawg ", data); 
                    } 

                    return {...data}; 
                }) 
            }
        } catch (err) { 
            console.log("GET ALBUM US TOTAL CONSUMPTION err ", err); 
        }
    }, [clientItem])

    const getUSPhysicalAlbums = useCallback(async (albumId: number, name: string) => { 
        try { 
            const body = { 
                albumId,
                weekId: props.weekId
            }

            const res = await axios.post(`/album/us/physical-albums`, body)
            if (res instanceof Error) throw (res); 
            if (res["status"] === 200 && res["data"] !== null) { 
                if (res["data"]["error"]) throw (res["data"]["error"]); 
                const total = res["data"]["total"]; 
                _setClientData((data: any) => { 
                         
                    if (data === null || !(clientItem in data)) { 

                        data = {
                            [clientItem]: { 
                                "U.S. Physical Albums": {
                                    tp: total["tp"],
                                    lp: total["lp"],
                                    ytd: total["ytd"],
                                    atd: total["atd"]
                                }
                            }
                        }

                        
                    } else { 
                        data[clientItem]["U.S. Physical Albums"] = {tp: total["tp"],
                                                                     lp: total["lp"],
                                                                     ytd: total["ytd"],
                                                                     atd: total["atd"]}
                        
                        console.log("the data bro ", data); 
                    } 

                    return {...data}; 
                        
                }) 
            }
        } catch (err) { 
            console.log("GET US PHYSICAL ALBUMS err ", err); 
        }
    }, [clientItem])

    const getTrackGlobalStreaming = useCallback(async (trackId: number, name: string) => { 
        try { 
            const body = { 
                trackId,
                weekId: props.weekId
            }

            const res = await axios.post(`/track/g1/streaming`, body)
            if (res instanceof Error) throw (res); 
            if (res["status"] === 200 && res["data"] !== null) { 
                if (res["data"]["error"]) throw (res["data"]["error"]); 
                const total = res["data"]["total"]; 
                const audio = res["data"]["audio"]; 
                const video = res["data"]["video"]; 
                _setClientData((data: any) => { 
               
                    if (data === null || !(clientItem in data)) { 

                        data = {
                            [clientItem]: { 
                                "Global Total Streaming": {
                                    tp: total["tp"],
                                    lp: total["lp"],
                                    ytd: total["ytd"],
                                    atd: total["atd"]
                                },

                                "Global Audio Streaming": { 
                                    tp: audio["tp"],
                                    lp: audio["lp"],
                                    ytd: audio["ytd"],
                                    atd: audio["atd"]
                                },

                                "Global Video Streaming": { 
                                    tp: video["tp"],
                                    lp: video["lp"],
                                    ytd: video["ytd"],
                                    atd: video["atd"]
                                }
                            }
                        }
                    } else { 
                            data[clientItem]["Global Total Streaming"] = {tp: total["tp"],
                                                                           lp: total["lp"],
                                                                           ytd: total["ytd"],
                                                                           atd: total["atd"]}
                            
                                
                            data[clientItem]["Global Audio Streaming"] = {tp: audio["tp"],
                                                                           lp: audio["lp"],
                                                                           ytd: audio["ytd"],
                                                                           atd: audio["atd"]}
                            
                                
                            data[clientItem]["Global Video Streaming"] = {tp: video["tp"],
                                                                           lp: video["lp"],
                                                                           ytd: video["ytd"],
                                                                           atd: video["atd"]}
                    } 

                    return {...data}; 
                
                
                }) 
           
            }
        } catch (err) { 
            console.log("GET TRACK GLOBAL STREAMING err ", err); 
        }
    }, [clientItem])

    const getGlobalTrackDownloads = useCallback(async (trackId: number, name: string) => { 
        try { 
            const body = { 
                trackId,
                weekId: props.weekId
            }

            const res = await axios.post(`/track/g1/track-downloads`, body)
            if (res instanceof Error) throw (res); 
            if (res["status"] === 200 && res["data"] !== null) { 
                if (res["data"]["error"]) throw (res["data"]["error"]); 
                const total = res["data"]["total"]; 
                _setClientData((data: any) => { 
                    
                    if (data === null || !(clientItem in data)) {

                        data = {
                            [clientItem]: { 
                                "Global Track Downloads": {
                                    tp: total["tp"],
                                    lp: total["lp"],
                                    ytd: total["ytd"],
                                    atd: total["atd"]
                                }
                            }
                        }
                    } else { 
                        
                        data[clientItem]["Global Track Downloads"] = {tp: total["tp"],
                                                                       lp: total["lp"],
                                                                       ytd: total["ytd"],
                                                                       atd: total["atd"]}
                    } 

                    return {...data}; 
                
                }) 
            }
        } catch (err) { 
            console.log("GET GLOBAL TRACK DOWNLOADS err ", err); 
        }
    }, [clientItem])

    const getTrackUSConsumption = useCallback(async (trackId: number, name: string) => { 
        try { 
            const body = { 
                trackId,
                weekId: props.weekId
            }

            const res = await axios.post(`/track/us/consumption`, body)
            if (res instanceof Error) throw (res); 
            if (res["status"] === 200 && res["data"] !== null) { 
                if (res["data"]["error"]) throw (res["data"]["error"]); 
                const total = res["data"]["total"]; 
                _setClientData((data: any) => { 
                    if (data === null || !(clientItem in data)) { 

                        data = {
                            [clientItem]: { 
                                "U.S. Track Consumption": {
                                    tp: total["tp"],
                                    lp: total["lp"],
                                    ytd: total["ytd"],
                                    atd: total["atd"]
                                }
                            }
                        }
                    } else { 
            
                    
                        data[clientItem]["U.S. Track Consumption"] = {tp: total["tp"],
                                                                       lp: total["lp"],
                                                                       ytd: total["ytd"],
                                                                       atd: total["atd"]}



                    } 

                    return {...data}; 
                       
                    
                    
                }) 
            }
        } catch (err) { 
            console.log("GET TRACK US CONSUMPTION err ", err); 
        }
    }, [clientItem])

    const getTrackUSStreaming = useCallback(async (trackId: number, name: string) => { 
        try { 
            const body = { 
                trackId,
                weekId: props.weekId
            }

            const res = await axios.post(`/track/us/streaming`, body)
            if (res instanceof Error) throw (res); 
            if (res["status"] === 200 && res["data"] !== null) { 
                if (res["data"]["error"]) throw (res["data"]["error"]); 
                const total = res["data"]["total"]; 
                
                _setClientData((data: any) => { 
                    
                    if (data === null || !(clientItem in data)) { 

                        data = {
                            [clientItem]: { 
                                "U.S. Total Streaming": {
                                    tp: total["tp"],
                                    lp: total["lp"],
                                    ytd: total["ytd"],
                                    atd: total["atd"]
                                }
                            }
                        }

                    } else { 
                        data[clientItem]["U.S. Total Streaming"] = {tp: total["tp"],
                                                                     lp: total["lp"],
                                                                     ytd: total["ytd"],
                                                                     atd: total["atd"]}
                    
                    } 

                    return {...data}; 
                   
                
                }) 
            }
        } catch (err) { 
            console.log("GET TRACK US STREAMING err ", err); 
        }
    }, [clientItem])



    useEffect(() => { 
        if (isRefreshed && clientDiscography[clientItem] !== undefined && clientDiscography[clientItem] !== null) { 
            const type = clientDiscography[clientItem]["type"];
            const id =  clientDiscography[clientItem]["id"]; 
            
            if (type === "Album") { 
                getAlbumUSTotalConsumption(id, clientItem);
                getUSPhysicalAlbums(id, clientItem); 
                getAlbumUSStreaming(id, clientItem); 
            } else { 
                getGlobalTrackDownloads(id, clientItem); 
                getTrackGlobalStreaming(id, clientItem); 
                getTrackUSConsumption(id, clientItem); 
                getTrackUSStreaming(id, clientItem); 
            }
            setIsRefreshed(false); 
        }
    }, [isRefreshed])

    useEffect(() => { 
        if (!!client?.MusicConnectArtistID && !!clientItem) {
            const type = clientDiscography[clientItem]["type"];
            const id =  clientDiscography[clientItem]["id"]; 
            if (type === "Album") { 
                console.log("GETTING DATA "); 
                getAlbumUSTotalConsumption(id, client);
                getUSPhysicalAlbums(id, client); 
                getAlbumUSStreaming(id, client); 
            } else { 
                getGlobalTrackDownloads(id, client); 
                getTrackGlobalStreaming(id, client); 
                getTrackUSConsumption(id, client); 
                getTrackUSStreaming(id, client); 
            }
        }
    }, [clientItem])


if (!!client && !!client.MusicConnectArtistID) {
    return (
        <div>
            <PhotoHolder photoName={photoName || ""} setPhotoUrl={setPhotoUrl} />
            {photoUrl ? (
          <div style={{ position: 'relative', height: '200px', marginBottom: '20px' }}>
          <div
            style={{
              backgroundImage: `url(${photoUrl})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              height: '100%',
              width: '100%',
            }}
          />
          <div style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
            fontWeight: 'bold',
            textTransform: 'uppercase', 
            fontFamily: 'rocnationFontSuisse',
            fontSize: '20px', 
            textAlign: 'center',
          }}>
            {client.Artist}
          </div>
        </div>
      ): (
        // Use a fallback image from assets if no photoUrl is available
        <div style={{ position: 'relative', height: '200px', marginBottom: '20px' }}>
           <div
        style={{
          backgroundImage: `url(${RocnationImage})`,
          backgroundSize: 'contain', 
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          height: '100%',
          width: '100%',
          filter: 'invert(40%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(80%) contrast(80%)', // Color change filter,
          backgroundColor: '#A1A4A6', 
        }}
      />
        <div style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
            fontWeight: 'bold',
            textTransform: 'uppercase', 
            fontFamily: 'rocnationFontSuisse',
            fontSize: '20px', 
            textAlign: 'center',
          }}>
            {client.Artist}
          </div>
        </div>
      )}

            {isAlbumOrTrack ? (
                <div>
              <Consumption
                client={client}
                data={_clientData}
                clients={clientOptions}
                refresh={handleRefresh}
                albumOrTrack={isAlbumOrTrack}
                discography={clientDiscography}
                item={clientItem}
              />
                 </div>
            ) : (
                <div>
              <AllClients
                photoName={photoName}
                client={client}
                clients={clientOptions}
                globalStreams={_globalStreams}
                globalAudioStreams={_globalAudioStreams}
                globalVideoStreams={_globalVideoStreams}
                globalTrackDownloads={_globalTrackDownloads}
                usPhysicalAlbums={_usPhysicalAlbums}
                totalConsumption={_totalConsumption}
                selectClient={handleSelectClient}
                refresh={handleRefresh}
                {...props}
              />
                   {_weeklyConsumption.length > 0 && <GlobalStreamingChart
                    chartData={_weeklyConsumption}
                />}
              </div>
            )
       
            
            }
          </div>
        );
} 
else {
        return (
          <TopSongs
            tracksGaniers={_trackGainers}
            refresh={handleRefresh}
          />
        );
    }
}

export default Container;
